import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Columns, Box, Section } from './bulma';

export default function Collection({ collection }) {
	const data = useStaticQuery(COLLECTION_QUERY);
	const { edges: posts } = data.allMarkdownRemark;

	return (
		<Section>
			{posts &&
				posts.map(({ node: post }) => (
					<article key={post.id} className={`blog-list-item box`}>
						<header>
							{post.frontmatter.image ? (
								<div className="featured-thumbnail">
									<PreviewCompatibleImage
										imageInfo={{
											image: post.frontmatter.image,
											alt: `featured image thumbnail for post ${post.title}`
										}}
									/>
								</div>
							) : null}
							<p className="post-meta">
								<Link className="title has-text-primary is-size-4" to={post.fields.slug}>
									{post.frontmatter.title}
								</Link>
							</p>
						</header>
						<p>
							{post.frontmatter.subheading}
							<br />
							<br />

							<Link className="button" to={post.fields.slug}>
								Lean More →
							</Link>
						</p>
					</article>
				))}
		</Section>
	);
}

Collection.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array
		})
	})
};

const COLLECTION_QUERY = graphql`
	query CollectionQuery {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "features-page" } } }
		) {
			edges {
				node {
					excerpt(pruneLength: 400)
					id
					fields {
						slug
					}
					frontmatter {
						title
						templateKey
						subheading
						date(formatString: "MMMM DD, YYYY")
						image {
							childImageSharp {
								fluid(maxWidth: 120, quality: 100) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}
	}
`;
