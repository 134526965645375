import React from 'react';

import Layout from '../../components/Layout';
import Features from '../../components/Features';

import Hero from '../../components/Hero';
import CTA from '../../components/CTA';

export default function FeaturesIndexPage() {
	return (
		<Layout>
			<Hero
				size="medium"
				title="Modern, feature-rich payment platform"
				subtitle="Come to save money, stay for everything else"
				// image={featuredImage}
				signup
			/>
			<Features />
			<CTA />
		</Layout>
	);
}
